<template>
  <div id="app">
    <!--主体-->
    <wyy-header />
    <router-view />
    <!--更新说明-->
    <wyy-dialog ref="versionDialog" type="alert" head-text="更新提示" :body-text="versionInfo" />
    <!--播放器-->
    <audio ref="wangyiyun"></audio>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { getPlaylistDetail } from 'api'
import { WYYPLAYER_CONFIG, VERSION } from '@/config'
import WyyHeader from 'components/wyy-header/wyy-header'
import WyyDialog from 'base/wyy-dialog/wyy-dialog'
import { getVersion, setVersion } from '@/utils/storage'

const VERSION_INFO = `<div class="wyy-dialog-text text-left">
版本号：V1.0 2024-2-20<br/>
重要通知：binaryify/netease_cloud_music_api<br/>
已被网易和谐，以后不再维护和更新，下个版本会改为nas的web端播放器。
docker镜像获取方式 docker pull yigencong/wangyiyun:v1.0（当前版本）
</div>`

export default {
  name: 'App',
  components: {
    WyyHeader,
    WyyDialog,
  },
  created() {
    // 设置版本更新信息
    this.versionInfo = VERSION_INFO

    // 获取正在播放列表
    getPlaylistDetail(WYYPLAYER_CONFIG.PLAYLIST_ID).then((playlist) => {
      const list = playlist.tracks.slice(0, 100)
      this.setPlaylist({ list })
    })

    // 设置title
    let OriginTitile = document.title
    let titleTime
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        document.title = '网抑云需要你点歌！'
        clearTimeout(titleTime)
      } else {
        document.title = '点首歌吧!'
        titleTime = setTimeout(function () {
          document.title = OriginTitile
        }, 2000)
      }
    })

    // 设置audio元素
    this.$nextTick(() => {
      this.setAudioele(this.$refs.wangyiyun)
    })

    // 首次加载完成后移除动画
    let loadDOM = document.querySelector('#appLoading')
    if (loadDOM) {
      const animationendFunc = function () {
        loadDOM.removeEventListener('animationend', animationendFunc)
        loadDOM.removeEventListener('webkitAnimationEnd', animationendFunc)
        document.body.removeChild(loadDOM)
        loadDOM = null
        const version = getVersion()
        if (version !== null) {
          setVersion(VERSION)
          if (version !== VERSION) {
            this.$refs.versionDialog.show()
          }
        } else {
          setVersion(VERSION)
          this.$refs.versionDialog.show()
        }
      }.bind(this)
      loadDOM.addEventListener('animationend', animationendFunc)
      loadDOM.addEventListener('webkitAnimationEnd', animationendFunc)
      loadDOM.classList.add('removeAnimate')
    }
  },
  methods: {
    ...mapMutations({
      setAudioele: 'SET_AUDIOELE',
    }),
    ...mapActions(['setPlaylist']),
  },
}
</script>

<style lang="less">
#app {
  position: relative;
  width: 100%;
  height: 100%;
  color: @text_color;
  font-size: @font_size_medium;

  audio {
    position: fixed;
  }
}
</style>
